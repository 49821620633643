<template>
  <v-container v-if="!loading" id="add-company" fluid tag="section">
    <base-material-card
      color="primary"
      icon="mdi-office-building"
      :title="company.CompanyName"
      class="px-5 py-3"
    >
      <v-tabs v-if="edit" v-model="tabs" @change="reset_alert">
        <v-tab>{{ $t("basic") }}</v-tab>
        <v-tab>{{ $t("financial") }}</v-tab>
        <v-tab>{{ $t("investment_conditions") }}</v-tab>
        <v-tab>{{ $t("media") }}</v-tab>
        <v-tab>{{ $t("market_news") }}</v-tab>
        <v-tab>{{ $t("team") }}</v-tab>
        <v-tab>{{ $t("highlights") }}</v-tab>
        <v-tab>{{ $t("company_analysis") }}</v-tab>
        <v-tab>{{ $t("files") }}</v-tab>
        <v-tab v-if="company.Presentation != null">{{
          $t("company_presentation")
        }}</v-tab>
      </v-tabs>

      <ManageCompanyBasic
        v-show="tabs == 0"
        @alert="show_alert"
        @saved="basic_saved"
        :company_prop="company"
        :roundBriefing="roundBriefing"
      ></ManageCompanyBasic>
      <ManageCompanyFinancial
        :BriefingId="briefingId"
        v-show="edit && tabs == 1"
        @alert="show_alert"
        @overview="change_financial_overview"
        @reset="update_analysis"
        :company_prop="company"
      ></ManageCompanyFinancial>
      <ManageCompanyInvestmentConditions
        :BriefingId="briefingId"
        :Partners="partners"
        v-show="edit && tabs == 2"
        @alert="show_alert"
        :company_prop="company"
      ></ManageCompanyInvestmentConditions>
      <ManageCompanyMedia
        :BriefingId="briefingId"
        v-show="edit && tabs == 3"
        :company_prop="company"
      ></ManageCompanyMedia>
      <ManageCompanyNews
        :BriefingId="briefingId"
        v-show="edit && tabs == 4"
        :company_prop="company"
      ></ManageCompanyNews>
      <ManageCompanyTeam
        :BriefingId="briefingId"
        v-show="edit && tabs == 5"
        :company_prop="company"
      ></ManageCompanyTeam>
      <ManageCompanyHighlights v-if="company.Id" v-show="tabs == 6" />
      <ManageCompanyAnalysis
        :BriefingId="briefingId"
        v-show="edit && tabs == 7"
        @reset="update_analysis"
        :company_prop="company"
      ></ManageCompanyAnalysis>
      <ManageCompanyFiles
        :roundBriefing="roundBriefing"
        :BriefingId="briefingId"
        v-show="edit && tabs == 8"
        :company_prop="company"
      ></ManageCompanyFiles>
      <ManageCompanyPresentation
        :BriefingId="briefingId"
        v-if="edit && tabs == 9 && company.Presentation != null"
        :company_prop="company"
      ></ManageCompanyPresentation>
      <v-alert
        class="ma-2"
        v-if="alert_msg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alert_msg) }}
      </v-alert>
    </base-material-card>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "ManageCompanyBriefing",

  components: {
    ManageCompanyBasic: () => import("./tabs/Basic"),
    ManageCompanyInvestmentConditions: () =>
      import("./tabs/InvestmentConditions"),
    ManageCompanyFinancial: () => import("./tabs/Financial"),
    ManageCompanyMedia: () => import("./tabs/Media"),
    ManageCompanyNews: () => import("./tabs/News"),
    ManageCompanyTeam: () => import("./tabs/Team"),
    ManageCompanyHighlights: () => import("./tabs/Highlights"),
    ManageCompanyAnalysis: () => import("./tabs/Analysis"),
    ManageCompanyFiles: () => import("./tabs/Files"),
    ManageCompanyPresentation: () => import("./tabs/PresentationSlides"),
  },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    briefingId: null,
    edit: false,
    success: null,
    alert_msg: null,
    partners: [],
    company: null,
    tabs: 0,
  }),

  computed: {},
  props: {
    briefingId: Number,
    roundBriefing: Boolean,
  },

  async created() {
    this.briefingId = parseInt(this.$route.params.briefingId);
    if (this.briefingId != null && this.briefingId != undefined) {
      this.loading = true;
      await this.apiService
        .postRequest(`companybriefing/get/${this.briefingId}`)
        .then((resp) => {
          this.company = JSON.parse(resp.message);
          this.roundBriefing = this.company.InvestmentRoundId > 0;
          this.edit = true;
        })
        .catch((error) => {
          this.edit = false;
        });
      await this.get_partners();
      this.loading = false;
    }

    // redirect para quando estiver usando a rodada retirar o acesso ao briefing pelo menu da empresa
    if (
      !this.roundBriefing &&
      process.env.VUE_APP_ROUNDS === "TRUE" &&
      this.company.IsBriefing
    ) {
      this.$router.push("/companies");
    }
  },

  methods: {
    async get_partners() {
      this.partners = await this.gs.GetPartnersB2b();
      this.partners.push({ name: "No Partner", id: 0 });
      this.partners = this.partners.sort((a, b) => a.id - b.id);
      this.initialize_company_partner_attrs("InvestmentConditionsIntro");
      this.initialize_company_partner_attrs("InvestmentConditionsText");
    },
    initialize_company_partner_attrs(att) {
      if (
        this.gs.isJson(this.company[att]) &&
        "pt" in JSON.parse(this.company[att])
      ) {
        this.company[att] = {
          0: JSON.parse(this.company[att]),
        };
      } else if (this.gs.isJson(this.company[att])) {
        this.company[att] = JSON.parse(this.company[att]);
      } else {
        var value = "";
        if (this.company[att] != null) {
          value = this.company[att].slice();
        }
        this.company[att] = {
          0: {},
        };
        this.company[att][0][this.$i18n.locale] = value;
      }
      for (var i = 0; i < this.partners.length; i++) {
        if (!this.company[att][this.partners[i].id]) {
          this.company[att][this.partners[i].id] = {};
          this.company[att][this.partners[i].id][this.$i18n.locale] = "";
        }
      }
    },
    basic_saved(company) {
      this.company = company;
    },
    reset_alert: function () {
      this.success = null;
      this.alert_msg = null;
    },
    show_alert: function (success, msg) {
      this.success = success;
      this.alert_msg = msg;
    },
    change_financial_overview(financial_overview) {
      this.company.FinancialOverview = financial_overview;
    },
    update_analysis: function (analysis) {
      this.reset_alert();
      this.loading = true;
      this.company.CompanyAnalysis.splice(
        0,
        this.company.CompanyAnalysis.length
      );
      var i;
      for (i = 0; i < analysis.length; i++) {
        this.company.CompanyAnalysis.push(analysis[i]);
      }
      this.loading = false;
    },
  },
};
</script>
